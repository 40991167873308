import type { IExperience } from "../types/Experience.ts";

const ListViewExperience = ({ data }: { data: Array<IExperience> }) => {
	return (
		<div className="border shadow-lg p-5 rounded-xl">
			<h1 className={"font-extrabold my-2"}>Experience</h1>
			<div className="space-y-9 divide-y">
				{data.map((item) => (
					<div
						className="grid grid-cols-6 md:grid-cols-12 py-2"
						key={item.company}
					>
						<img
							src={item.logo}
							width={0}
							height={0}
							className="rounded-2xl border-gray-100 border h-24 w-24 p-1 md:col-span-2 col-span-2"
							alt={item.company}
						/>
						<div className="p-3 w-full space-y-3 col-span-4 md:col-span-10">
							<div className="flex justify-between">
								<div className="text-gray-900">{item.position}</div>
								<div className="font-medium text-right text-gray-600 inline-flex">
									<div className="text-gray-900">
										<span className="shadow-lg shadow-waikawa-gray-500 bg-gradient-to-r from-waikawa-gray-600 to-waikawa-gray-800 rounded-xl py-2 px-1.5 text-white text-xs">
											{item.type}
										</span>
									</div>
								</div>
							</div>
							<div className="flex md:justify-between flex-wrap gap-2">
								<div className="flex items-center leading-3 text-gray-600 gap-1">
									<img
										src={"icons/briefcase.svg"}
										width={20}
										height={20}
										alt={"Work"}
									/>
									<span className={"text-sm"}>{item.company}</span>
								</div>
								<div className="flex items-center leading-3 text-gray-600 gap-1">
									<img
										src={"icons/location.svg"}
										width={20}
										height={20}
										alt={"Day"}
									/>
									<span className={"text-sm"}>{item.location}</span>
								</div>
								<div className="font-medium text-right text-gray-600 inline-flex">
									<img
										src={"icons/calendar-days.svg"}
										width={20}
										height={20}
										alt={"Day"}
									/>
									<span className={"pl-1 text-sm"}>{item.duration}</span>
								</div>
							</div>
						</div>
						<ul className="my-2 list-inside col-span-6 md:col-span-12 rounded-lg p-3 pb-4 pl-5 relative overflow-hidden border-l-8 border-waikawa-gray-200">
							{item.description.content.map((line: string, index: number) => (
								<li key={index} className={""}>
									{line}
								</li>
							))}
						</ul>
					</div>
				))}
			</div>
		</div>
	);
};
export default ListViewExperience;
