import type { IAvatar } from "../types/Experience.ts";

const Avatar = ({ data }: { data: IAvatar }) => {
	return (
		<div className="border shadow-lg p-5 rounded-xl">
			<div className="space-y-3 divide-y divide-dotted flex">
				<div className="justify-center object-center items-center text-left my-1">
					<img
						src={data.image}
						alt={data.alt}
						width={100}
						height={100}
						className={"rounded-xl border-gray-200 border"}
					/>
					<span>
						{data.name}
						<span className={"text-gray-500"}>({data.nickname})</span>
					</span>
					<br />
					<span className={"text-gray-500 font-light text-base"}>
						{data.position}
					</span>
				</div>
			</div>
			<div className="space-y-3 divide-y divide-dotted my-2">
				<div className="flex justify-between">
					<div className="text-gray-600">Experience</div>
					<div className="font-medium text-right text-gray-600">
						{data.experience}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Avatar;
