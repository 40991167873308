import atSymbolIcon from "../../public/icons/at-symbol.svg";
import phoneIcon from "../../public/icons/phone.svg";
import type { IAboutMe } from "../types/Experience.ts";

const AboutMe = ({ data }: { data: IAboutMe }) => {
	return (
		<div className="border shadow-lg p-5 rounded-xl">
			<h1 className={"font-extrabold my-2"}>About Me</h1>
			<p className={"text-gray-600"}>{data.description}</p>
			<div className="flex mt-1">
				<div className={"text-blue-700"}>
					<a href={`mailto:${data.email}`}>
						<img
							src={atSymbolIcon}
							width={20}
							height={20}
							className={"mr-1 h-5 inline-block"}
							alt={"Symbol"}
						/>
						{data.email}
					</a>
				</div>
			</div>
			<div className="flex mt-1">
				<div className="text-blue-700">
					<img
						src={phoneIcon}
						width={20}
						height={20}
						className={"mr-1 h-5 inline-block"}
						alt={"Symbol"}
					/>
					<a href={`tel:${data.phoneNumber}`}>{data.phoneNumber}</a>
				</div>
			</div>
			{data.link.map((link, _) => (
				<div key={link.url} className="flex mt-1 text-blue-700">
					<a href={link.url} className={"inline-flex text-blue-600 mr-1 h-5"}>
						<img
							src={`/icons/${link.name}.svg`}
							width={20}
							height={20}
							className={"mr-1 h-5 inline-block"}
							alt={"Symbol"}
						/>
						{link.name.charAt(0).toUpperCase() + link.name.slice(1)}
					</a>
				</div>
			))}
		</div>
	);
};
export default AboutMe;
