import type { IEducation } from "../types/Experience.ts";

const Educations = ({ data }: { data: Array<IEducation> }) => {
	return (
		<div className="border shadow-lg p-5 rounded-xl">
			<h1 className="font-extrabold my-2">Education</h1>
			<ol className="relative border-l border-gray-400">
				{data.map((edu) => (
					<li key={edu.school} className="mb-6 ml-4">
						<div className="absolute w-3 h-3 bg-gray-500 rounded-full mt-1.5 -left-1.5 border border-white" />
						<time className="mb-1 text-sm font-normal text-gray-600">
							{edu.graduationDate}
						</time>
						<h3 className="text-lg font-semibold text-gray-900">
							{edu.school}
						</h3>
						<p className="text-base font-normal text-gray-500">{edu.degree}</p>
					</li>
				))}
			</ol>
		</div>
	);
};

export default Educations;
