import type { ISkill } from "../types/Experience.ts";

const Skills = ({ data }: { data: ISkill[] }) => {
	return (
		<>
			<div className="border shadow-lg p-5 rounded-xl">
				<h1 className={"font-extrabold my-2"}>Skills</h1>

				<div className="space-y-3 divide-y divide-dotted">
					{data.map((skill) => (
						<div key={skill.title} className="flex justify-between">
							<div className="text-gray-600 font-light">{skill.title}</div>
							<div className="font-medium text-right text-gray-800">
								{skill.items.join(", ")}
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default Skills;
