import type { ICertificate } from "../types/Experience.ts";

const Certificates = ({ data }: { data: Array<ICertificate> }) => {
	return (
		<div className="border shadow-lg p-5 rounded-xl">
			<div className="flex items-center gap-2">
				<h1 className={"font-extrabold my-2"}>Certificates</h1>
				<span className={"text-gray-700 text-sm"}>Click to view more info</span>
			</div>
			<div className="space-y-3 divide-y divide-dotted">
				{data.map((cert) => (
					<div key={cert.code} className="font-medium text-gray-600">
						<a href={cert.url}>
							<span className="font-bold">{cert.code} </span>
							{cert.name}
						</a>
					</div>
				))}
			</div>
		</div>
	);
};
export default Certificates;
