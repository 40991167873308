import "./main.css";
import Avatar from "./components/Avatar.tsx";
import Skills from "./components/Skills.tsx";
import AboutMe from "./components/AboutMe.tsx";
import ListViewExperience from "./components/ListViewExperience.tsx";
import Educations from "./components/Educations.tsx";
import Certificates from "./components/Certificates.tsx";
import data from './cv.json'

export function Main() {

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-3 lg:mx-12 xl:mx-36 mx-2 my-4 lg:my-2 gap-4">
        <div className="space-y-5">
          <Avatar data={data.avatar}/>
          <Skills data={data.skill}/>
        </div>
        <div className="space-y-5 lg:col-span-2">
          <AboutMe data={data.aboutMe}/>
          <ListViewExperience data={data.experience}/>
          <Educations data={data.education}/>
          <Certificates data={data.certificates}/>
        </div>
      </div>
    </>
  );
}
